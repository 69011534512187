import React from 'react';

const IconAndroid = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 96 96">
    <title>Android</title>
    <path
      d="M29,67c0,1.7,1.4,3.2,3.2,3.2h3.2v11.1c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8V70.2h6.3
		v11.1c0,2.6,2.1,4.8,4.7,4.8c2.6,0,4.8-2.1,4.8-4.8V70.2h3.2c1.7,0,3.2-1.4,3.2-3.2V35.3H29V67z M21.1,35.3c-2.6,0-4.8,2.1-4.8,4.8
		v22.2c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8V40.1C25.8,37.5,23.7,35.3,21.1,35.3z M74.9,35.3c-2.6,0-4.8,2.1-4.8,4.8v22.2
		c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8V40.1C79.7,37.5,77.5,35.3,74.9,35.3z M59.2,16.8l4.1-4.1c0.6-0.6,0.6-1.6,0-2.2
		c-0.6-0.6-1.6-0.6-2.2,0l-4.7,4.7c-2.5-1.3-5.4-2-8.4-2c-3,0-5.9,0.7-8.4,2l-4.7-4.7c-0.6-0.6-1.6-0.6-2.2,0
		c-0.6,0.6-0.6,1.6,0,2.2l4.1,4.1c-4.7,3.5-7.8,9-7.8,15.3h38C67,25.9,63.9,20.3,59.2,16.8z M41.7,25.8h-3.2v-3.2h3.2V25.8z
    M57.5,25.8h-3.2v-3.2h3.2V25.8z"
    />
  </svg>
);

export default IconAndroid;
